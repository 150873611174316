<template>
	<van-popup v-model="visible" round position="bottom" closeable :style="{ height: '60%' }">
		<div class="place-container">
			<p class="title">{{ title }}</p>
			<div class="content-wrap">
				<van-form ref="vanForm" label-width="60px">
					<van-field v-model="formData.name" label="姓名" placeholder="请输入姓名" :rules="[{ required: true, message: '请输入姓名' }]" />
					<van-field v-model="formData.label" label="工号" placeholder="请输入工号" />
					<van-field v-model="formData.tel" label="电话" placeholder="请输入电话" />
					<van-field v-model="formData.tenantName" label="科室" placeholder="请选择科室" @focus="tenantFocus" :rules="[{ required: true, message: '请输入姓名' }]" />
					<KcCascader
						ref="tenantSelect"
						v-model="formData.tenantId"
						:options="formOptions.tenantOptions"
						:props="{ label: 'name', value: 'id' }"
						@submit="tenantChange"
					></KcCascader>

					<van-field
						v-model="sensorData.label"
						label="手环"
						placeholder="请输入手环编号"
						:rules="[{ validator: validatorSensor, message: '手环无法使用，请输入其他手环编号' }]"
					/>
				</van-form>
				<div class="handle-btn">
					<button type="button" class="reset-btn" @click="reset">重置</button>
					<button type="button" class="submit-btn" @click="submit">提交</button>
				</div>
			</div>
		</div>
	</van-popup>
</template>

<script>
import tenantFormDataMixin from '@/mixins/tenantMixins/tenantFormDataMixin.js';
export default {
	name: 'addPopup',
	components: {},
	mixins: [tenantFormDataMixin],
	data() {
		return {
			title: '添加工勤人员',
			visible: false,

			formData: {
				name: '',
				label: '',
				tel: '',
				tenantId: '',
				tenantName: ''
			},
			formData_backup: null,
			formOptions: {
				tenantOptions: []
			},
			infoData: null,
			sensorData: {
				label: ''
			}
		};
	},
	created() {
		this.formData_backup = JSON.parse(JSON.stringify(this.formData));
	},
	mounted() {},
	methods: {
		show(info) {
			this.visible = true;
			if (!info) {
				this.title = '添加工勤人员';
				this.formData = JSON.parse(JSON.stringify(this.formData_backup));
				this.sensorData = { label: '' };
			} else {
				this.title = '编辑工勤人员';
				const _info = JSON.parse(JSON.stringify(info));
				delete _info.sensorList;
				delete _info.groupList;
				this.formData = _info;
				if (info.sensorList && info.sensorList.length) {
					this.sensorData = info.sensorList[0];
				}
			}
			this.infoData = info;
		},
		hide() {
			this.visible = false;
		},

		tenantFocus() {
			this.$refs.tenantSelect.show();
		},
		tenantChange(e, item) {
			this.formData.tenantId = item.id;
			this.formData.tenantName = item.name;
		},
		validatorSensor() {
			return new Promise((resolve, reject) => {
				if (!this.sensorData.label) {
					this.sensorData = {
						label: ''
					};
					return resolve();
				}
				this.$api.transportManage
					.getNurseStaffDeviceDetail({
						query: this.sensorData.label
					})
					.then(res => {
						if (!res.data.medicId) {
							reject();
						} else {
							this.sensorData = res.data;
							resolve();
						}
					})
					.catch(() => {});
			});
		},

		reset() {
			this.formData = JSON.parse(JSON.stringify(this.formData_backup));
		},
		submit() {
			this.$refs.vanForm
				.validate()
				.then(() => {
					this.$dialog
						.confirm({
							message: '确定提交？'
						})
						.then(() => {
							this.$api.transportManage
								.addPersonal({
									...this.formData,
						 		type: 1
								})
								.then(res => {
									// 手环部分先不接

									// if(this.infoData){
									// 	if(this.infoData.sensorList&&this.infoData.sensorList.length){
									// 		if(this.sensorData.id&&this.sensorData.id!=this.infoData.sensorList[0].id){
									// 			// 编辑工勤-更换绑定手环
									// 		}else if(!this.sensorData.id){
									// 			// 编辑工勤-更换绑定手环
									// 		}
									// 	}
									// }else{
									// 	if(this.sensorData.id){
									// 		// 新增工勤-绑定设备
									// 	}
									// }
									this.$toast.success('操作成功');
									this.$emit('update');
									this.hide();
								})
								.catch(() => {
									this.$toast.fail('操作失败');
								});
						})
						.catch(() => {});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
.place-container {
	width: 100%;
	height: 100%;
	padding: 10px;
	position: relative;
	padding-top: 46px;
	> .title {
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		color: #666;
		line-height: 22px;
		left: 0;
		right: 0;
		top: 14px;
	}
	.content-wrap {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		.handle-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 32px;
			button {
				line-height: 32px;
				width: 40%;
				text-align: center;
				border-radius: 32px;
				font-size: 14px;
			}
			.reset-btn {
				border: 1px solid #ccc;
				color: #333;
				background: none;
			}
			.submit-btn {
				margin-left: 20px;
				background: #3e73fb;
				color: #fff;
			}
		}
	}
}
</style>
