<template>
	<div class="main-wrap">
		<div class="main-header">
			<searchInput v-model="baseData.query" placeholder="搜索工勤人员" @search="getList"></searchInput>
			<button type="button" class="add-btn" @click="addEvent()">
				<van-icon name="plus" />
				添加
			</button>
		</div>

		<div class="content-list">
			<div class="item" v-for="(item, index) in tableData" :key="index">
				<div class="info-wrap">
					<div class="info-row">
						<span class="label">姓名:</span>
						<span class="value">{{ item.name || '-' }}</span>
					</div>
					<div class="info-row">
						<span class="label">工号:</span>
						<span class="value">{{ item.label || '-' }}</span>
					</div>
					<div class="info-row">
						<span class="label">科室:</span>
						<span class="value">{{ item.tenantName || '-' }}</span>
					</div>
					<div class="info-row">
						<span class="label">电话:</span>
						<span class="value">{{ item.tel || '-' }}</span>
					</div>
					<div class="info-row">
						<span class="label">手环:</span>
						<span class="value">{{ item.sensorList && item.sensorList.length ? item.sensorList[0].label : '-' }}</span>
					</div>
				</div>

				<div class="handle-wrap">
					<button type="button" class="danger-btn" @click="deleteEvent(item)">删除</button>
					<button type="button" class="primary-btn" @click="addEvent(item)">编辑</button>
				</div>
			</div>

			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>

		<addPopup ref="addPopup" @update="getList()"></addPopup>
	</div>
</template>

<script>
import addPopup from './addPopup.vue';
export default {
	name: 'workers',
	components: { addPopup },
	data() {
		return {
			baseData: {
				query: ''
			},

			tableData: []
		};
	},
	created() {
		this.getList()
	},
	mounted() {},
	methods: {
		getList() {
			this.$api.transportManage
				.getPersonalList({
					authType: 1,
					deleted: 0,
					query: this.baseData.query,
					type: 1
				})
				.then(res => {
					this.tableData = res.data;
				})
				.catch(() => {});
		},
		deleteEvent(item) {
			this.$dialog
				.confirm({
					message: '确定删除此工勤人员？'
				})
				.then(() => {
					this.$api.transportManage
						.deletedPersonal({
							id: item.id
						})
						.then(res => {
							this.$toast.success('操作成功');
							this.getList();
						})
						.catch(e => {
							this.$toast.fail('操作失败');
						})
						.finally(() => {});
				})
				.catch(() => {});
		},
		addEvent(item) {
			this.$refs.addPopup.show(item || null);
		}
	}
};
</script>

<style lang="scss" scoped>
.main-wrap {
	height: 100%;
	.main-header {
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		::v-deep.searchInput {
			width: calc(100% - 62px);
		}
		.add-btn {
			font-size: 14px;
			font-weight: 700;
			color: #fff;
			background: none;
		}
	}
	.content-list {
		height: calc(100% - 52px);
		overflow-y: auto;
		padding: 0 10px;
		margin-top: 10px;
		.item {
			background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
			box-shadow: 0px 1px 1px 0px rgba(100, 101, 102, 0.1);
			border-radius: 8px;
			padding: 8px 12px;
			&:not(:first-child) {
				margin-top: 10px;
			}
			.info-wrap {
				display: flex;
				flex-wrap: wrap;
				.info-row {
					width: 50%;
					font-size: 14px;
					display: flex;
					align-items: center;
          $labelWidth: 50px;
					padding: 2px 0;
					.label {
						color: #666;
						width: #{$labelWidth};
					}
					.value {
						color: #333;
						width: calc(100% - #{$labelWidth});
					}
				}
			}
			.handle-wrap {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-top: 16px;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: -8px;
					border-top: 1px solid #ddd;
				}
				button {
					padding: 0px 16px;
					line-height: 24px;
					border-radius: 24px;
					border: 1px solid #3e73fb;
					color: #3e73fb;
					font-size: 14px;
					background: none;
					&:not(:last-child) {
						margin-right: 10px;
					}
					&.danger-btn {
						border-color: red;
						color: red;
					}
				}
			}
		}
	}
}
</style>
