export default {
	data() {
		return {
			defaultTenant: []
		}
	},
	created() {
		this.formOptions.tenantOptions = this.defaultTenant
		this.setTenantOptions()
	},
	mounted() {},
	methods: {
		setTenantOptions() {
			let orgInfo = this.$store.getters.orgInfo || []

			const setInfo = () => {
				let list = JSON.parse(JSON.stringify(orgInfo))
				list = this.defaultTenant.concat(list)

				this.formOptions.tenantOptions = list
			}


			if (orgInfo.length) {
				setInfo()
			} else {
				this.$api.userInfo.getListTreeTenantAuthority()
					.then(res => {
						let jsonStr = JSON.stringify(res.data)
						jsonStr = jsonStr.replace(/title/g, 'name')
						jsonStr = jsonStr.replace(/child/g, 'children')
						const list = JSON.parse(jsonStr)
						this.$store.commit('setOrgInfo', list)
						orgInfo = list
						setInfo()
					})
					.catch(() => {})
			}
		}
	}
}
